import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import "./CountDown.css";
import { Spinner } from "react-bootstrap";
import xImg from "../../images/white-small-X.svg";
import until_mobile_backgroundImg from "../../images//countDown/CountDownBackgroundMobile.png";
import until_desktop_backgroundImg from "../../images//countDown/CountDownBackgroundDesktop.png";
import { useMediaQuery } from "react-responsive";

const CountDown = (props) => {
  const {
    footerHeight,
    onClose,
    nextRoundName,
    nextRoundTime,
    entriesUntilOrFrom,
    counterPosition,
    enableClose,
  } = props;
  const [isFixedToBottom, setIsFixedToBottom] = useState(false);
  const [countDownBg, setCountDownBg] = useState(null);
  const [timerTimeLeft, setTimerTimeLeft] = useState(null);

  const isMobileScreen = useMediaQuery({
    query: "(max-width: 767px)",
  });

  useEffect(() => {
    counterPosition === "bottom"
      ? setIsFixedToBottom(true)
      : setIsFixedToBottom(false);
  }, [counterPosition]);

  let nextRoundSchedule = moment(nextRoundTime).format(
    "ddd D MMMM [a las] HH:mm"
  );

  let isNextRound = moment().isBefore(moment(nextRoundTime));

  const calcTimeLeft = () => {
    let timeLeft = {};
    const countDownTime = new Date(nextRoundTime).getTime();
    const currentTime = new Date().getTime();
    const difference = countDownTime - currentTime;

    if (difference > 0) {
      timeLeft = {
        days:
          Math.floor(difference / (1000 * 60 * 60 * 24)) < 10
            ? "0" + Math.floor(difference / (1000 * 60 * 60 * 24))
            : Math.floor(difference / (1000 * 60 * 60 * 24)),

        hours:
          Math.floor((difference / (1000 * 60 * 60)) % 24) < 10
            ? "0" + Math.floor((difference / (1000 * 60 * 60)) % 24)
            : Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes:
          Math.floor((difference / 1000 / 60) % 60) < 10
            ? "0" + Math.floor((difference / 1000 / 60) % 60)
            : Math.floor((difference / 1000 / 60) % 60),
        seconds:
          Math.floor((difference / 1000) % 60) < 10
            ? "0" + Math.floor((difference / 1000) % 60)
            : Math.floor((difference / 1000) % 60),
      };
      return timeLeft;
    } else
      return (timeLeft = {
        days: "0",
        hours: "0",
        minutes: "0",
        seconds: "0",
      });
  };

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setTimerTimeLeft(calcTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const configureCountDownCssClass = () => {
    return isMobileScreen && isFixedToBottom ? "-fixedCounter" : "";
  };

  const configureCountDownInlineStyle = useCallback(() => {
    if (entriesUntilOrFrom === "until") {
      if (isMobileScreen && isFixedToBottom) {
        setCountDownBg({
          backgroundImage: `url(${until_mobile_backgroundImg})`,
          bottom: footerHeight,
        });
      } else
        setCountDownBg({
          backgroundImage: `url(${until_desktop_backgroundImg})`,
        });
    } else if (isFixedToBottom) {
      setCountDownBg({
        backgroundImage:
          "linear-gradient(99.83deg, #79C000 2.49%, #97ED05 95.8%)",
        bottom: footerHeight,
      });
    } else
      setCountDownBg({
        backgroundImage:
          "linear-gradient(99.83deg, #79C000 2.49%, #97ED05 95.8%)",
      });
  }, [entriesUntilOrFrom, footerHeight, isFixedToBottom, isMobileScreen]);

  useEffect(() => {
    configureCountDownInlineStyle();
  }, [configureCountDownInlineStyle, entriesUntilOrFrom, isMobileScreen]);

  const timerComponents = [];
  const timeFormat = ["DAYS", "HRS", "MIN", "SEC"];

  timerTimeLeft &&
    Object.keys(timerTimeLeft).forEach((clockItem, index) => {
      timerComponents.push(
        <div key={index} className="clockItem-box__CountDown">
          <div
            className={`clockItem${configureCountDownCssClass()}__CountDown`}
          >
            {timerTimeLeft[clockItem]}
            <div
              className={`littleWhiteSquere-right${configureCountDownCssClass()}__CountDown`}
            />
            <div
              className={`littleWhiteSquere-left${configureCountDownCssClass()}__CountDown`}
            />
          </div>
          <div className="clock-timeText__CountDown">
            {isMobileScreen && isFixedToBottom
              ? timeFormat[index]
              : clockItem.toUpperCase()}
          </div>
        </div>
      );
    });

  if (timerTimeLeft === null && !isFixedToBottom) {
    return (
      <Spinner animation="border" style={{ color: "rgba(121, 192, 0, 1)" }} />
    );
  }

  return (
    <>
      {timerTimeLeft && (
        <div
          data-html2canvas-ignore
          className={`clock-container${configureCountDownCssClass()}__CountDown`}
          style={countDownBg}
        >
          {enableClose && (
            <img
              onClick={onClose}
              className={`xIcon${configureCountDownCssClass()}__CountDown`}
              src={xImg}
              alt="close count down"
            />
          )}
          <div className={`details__CountDown`}>
            <div
              className={`clock-title${configureCountDownCssClass()}__CountDown`}
            >
              {isNextRound
                ? `${nextRoundName} EMPIEZA`
                : `esperando la siguiente jornada`}
            </div>
            <div
              className={`clock-nextRoundSchedule${configureCountDownCssClass()}__CountDown`}
            >
              {isNextRound ? `${nextRoundSchedule}` : ""}
            </div>
          </div>
          <div className={`clock__CountDown`}>{timerComponents}</div>
        </div>
      )}
    </>
  );
};

export default CountDown;
