import React, { useContext, useEffect, useState } from "react";
import trophy from "./images/mobile-footer/trophy.svg";
import target from "./images/mobile-footer/target.svg";
import leaderboard from "./images/mobile-footer/leaderboardTab.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ResultsScheduleContext } from "./contexts/ResultsScheduleContext";

import {
  CURRENT_LEAGUE_ENDPOINT_1,
  CURRENT_LEAGUE_ENDPOINT_2,
} from "./leagues/leagues";
import { PredictionsContext } from "./contexts/PredictionsContext";
import ModalExit from "./Modal/ModalExit";

const footerStyle = {
  fontSize: 10,
  fontWeight: 500,
  color: "#353D49",
};

const activeTabStyle = {
  backgroundColor: "#BCDF80",
};
const nonActiveTabStyle = {
  backgroundColor: "white",
};

const linkStyle = {
  textDecoration: "none",
  color: "rgba(53, 61, 73, 1)",
  width: "100%",
  height: "100%",
  display: "block",
  cursor: "pointer",
};

function Footer({ forwardedRef }) {
  const [show, setShow] = useState(false);
  const { isModify, setModify } = useContext(PredictionsContext);
  const [moveTo, setMoveTo] = useState(null);
  const history = useHistory();

  const location = useLocation();

  const { nextEnglishRoundSubmission, nextSpanishRoundSubmission } = useContext(
    ResultsScheduleContext
  );

  const [league, setLeague] = useState(CURRENT_LEAGUE_ENDPOINT_1);

  useEffect(() => {
    switch (location.pathname.split("/").filter((str) => str.length)[0]) {
      case CURRENT_LEAGUE_ENDPOINT_1:
        setLeague(CURRENT_LEAGUE_ENDPOINT_1);
        break;
      case CURRENT_LEAGUE_ENDPOINT_2:
        setLeague(CURRENT_LEAGUE_ENDPOINT_2);
        break;
      default:
    }
  }, [location]);

  let activeTab;
  const activeAccum = location.pathname
    .split("/")
    .filter((str) => str.length)[1];
  switch (location.pathname.split("/").filter((str) => str.length)[1]) {
    case "1x2":
    case "u-o-2.5-goals":
    case "bbts":
      activeTab = "accumulator";
      break;
    default:
  }

  if (!activeTab) {
    if (
      location.pathname.split("/").filter((str) => str.length)[0] ===
      "results-schedule"
    ) {
      activeTab = "results";
    } else if (
      location.pathname.split("/").filter((str) => str.length)[0] ===
      "leaderboard"
    ) {
      activeTab = "leaderboard";
    } else {
      activeTab = "predictions";
    }
  }

  const clickAccumulations = () => {
    if (isModify) {
      setShow(true);
      setMoveTo(`/${league}/1x2`);
    } else {
      history.push(`/${league}/1x2`);
    }
  };
  const clickResult = () => {
    if (isModify) {
      setMoveTo(`/results-schedule/${league}`);
      setShow(true);
    } else {
      history.push(`/results-schedule/${league}`);
    }
  };
  const clickLeaderboard = () => {
    if (isModify) {
      setMoveTo(`/leaderboard/${league}`);
      setShow(true);
    } else {
      history.push(`/leaderboard/${league}`);
    }
  };

  const backHandler = () => {
    setShow(false);
    setModify(false);
    if (moveTo) {
      history.push(moveTo);
    }
    return;
  };

  return (
    <div
      ref={forwardedRef}
      className={`footer text-center container-fluid fixed-bottom`}
    >
      <div className="row flex-nowrap">
        <ModalExit ready={show} setReady={setShow} back={backHandler} />
        <div
          style={activeTab === "results" ? activeTabStyle : nonActiveTabStyle}
          className="col p-0"
        >
          <div style={linkStyle} onClick={() => clickResult()}>
            <img className="footer-img" src={trophy} alt="target" />
            <p className="footer-link" style={footerStyle}>
              RESULTADOS
            </p>
          </div>
        </div>

        <div
          style={
            activeTab === "predictions" ? activeTabStyle : nonActiveTabStyle
          }
          className="col p-0"
        >
          <Link style={linkStyle} to={`/${league}`}>
            <img className="footer-img" src={target} alt="target" />
            <p className="footer-link" style={footerStyle}>
              {(nextEnglishRoundSubmission?.submission &&
                league === CURRENT_LEAGUE_ENDPOINT_2) ||
              (nextSpanishRoundSubmission?.submission &&
                league === CURRENT_LEAGUE_ENDPOINT_1)
                ? "EDITAR PRONÓSTICO"
                : "PRONÓSTICO"}
            </p>
          </Link>
        </div>

        <div
          style={
            activeTab === "leaderboard" ? activeTabStyle : nonActiveTabStyle
          }
          className="col p-0"
        >
          <div style={linkStyle} onClick={() => clickLeaderboard()}>
            <img className="footer-img" src={leaderboard} alt="leaderboard" />
            <p className="footer-link" style={footerStyle}>
              CLASIFICACIÓN
            </p>
          </div>
        </div>

        <div
          style={
            activeTab === "accumulator" ? activeTabStyle : nonActiveTabStyle
          }
          className="col p-0"
        >
          <div style={linkStyle} onClick={() => clickAccumulations()}>
            <img className="footer-img" src={target} alt="target" />
            <p className="footer-link" style={footerStyle}>
              PARLAYS
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
