import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
// import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  CURRENT_LEAGUE_ENDPOINT_1,
  CURRENT_LEAGUE_ENDPOINT_2,
} from "../leagues/leagues";
import { PredictionsContext } from "../contexts/PredictionsContext";

const useStyles = makeStyles((theme) => ({
  customTwo: {
    fonWeight: 1000,
    fontSize: 12,
  },
}));

export default function ResultsTabs({ league }) {
  const location = useLocation();

  const {
    spanish_RoundNumber_CurrentRound,
    spanish_RoundNumber_NextRound,
    spanish_RoundNumber_LastRound,
  } = useContext(PredictionsContext);

  const [selectedTab, setTabSelected] = React.useState(0);

  // fix of tab indicator bug
  useEffect(() => {
    if (
      location.pathname === `/results-schedule/${CURRENT_LEAGUE_ENDPOINT_2}`
    ) {
      setTabSelected(1);
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setTabSelected(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabSelected(index);
  };

  const routes = [CURRENT_LEAGUE_ENDPOINT_1, CURRENT_LEAGUE_ENDPOINT_2];
  const league1ToUpper = CURRENT_LEAGUE_ENDPOINT_1.toLocaleUpperCase();
  const league2ToUpper = CURRENT_LEAGUE_ENDPOINT_2.toLocaleUpperCase();

  return (
    <div className="container-fluid p-0 tabs-container matches-specific">
      <div color="default">
        <Tabs
          TabIndicatorProps={{
            style: { height: 3, background: "rgba(121, 192, 0, 1)" },
          }}
          value={selectedTab}
          onChange={handleChange}
          textColor="primary"
          variant="fullWidth"
          aria-label="tabs"
        >
          {/* <Tab label={spanish_RoundNumber_CurrentRound ||
            spanish_RoundNumber_NextRound || spanish_RoundNumber_LastRound} component={Link} to={routes[0]} /> */}
          <Tab
            label={CURRENT_LEAGUE_ENDPOINT_1}
            component={Link}
            to={routes[0]}
          />
          {/* <Tab label={`${league2ToUpper}`} component={Link} to={routes[1]} /> */}
        </Tabs>
      </div>
    </div>
  );
}
