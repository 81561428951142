import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { BASE_URL } from "../environment";
import { UserContext } from "../contexts/UserContext";
import localforage from "localforage";
import moment from "moment";
import { ResultsScheduleContext } from "./ResultsScheduleContext";
import { CURRENT_CAMPAIGN_ID } from "../leagues/leagues";
import ReactGA from "react-ga";
import PagesEnum from "../enums/pages";

export const PredictionsContext = createContext();

export const PredictionsProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const {
    setEnglishPastRoundsPredictions,
    setEnglishNextRound,
    setEnglishPredictionsForNextRound,
    setnextEnglishRoundSubmission,
    setSpanishPastRoundsPredictions,
    setSpanishNextRound,
    setSpanishPredictionsForNextRound,
    setnextSpanishRoundSubmission,
    isDataOld,
    setIsDataOld,
    spanishNextRound,
  } = useContext(ResultsScheduleContext);
  const [loading, setLoading] = useState(true);
  const [isModify, setModify] = useState(false);
  const [currentLeague, setLeague] = useState(null);
  const [spanishEntriesFrom, setSpanishEntriesFrom] = useState(null);
  const [spanishEntriesUntil, setSpanishEntriesUntil] = useState(null);
  const [banners, setBanners] = useState(null);
  const [countDownData, setCountDownData] = useState({
    entriesUntilOrFrom: null,
    roundTime: null,
    roundName: null,
  });
  const [showCountDown, setShowCountDown] = useState(true);

  const [predictionArray, setPrediction] = useState([]);
  const [english_Matches_NextRound, setEnglish_Matches_NextRound] = useState(
    []
  );

  const [english_League_NextRound, setEnglish_League_NextRound] =
    useState(null);
  const [english_RoundNumber_NextRound, setEnglish_Round_NextRound] =
    useState(null);
  const [english_RoundId_NextRound, setEnglish_RoundId_NextRound] =
    useState(null);

  const [english_Matches_CurrentRound, setEnglish_Matches_CurrentRound] =
    useState([]);
  const [english_League_CurrentRound, setEnglish_League_CurrentRound] =
    useState(null);
  const [
    english_RoundNumber_CurrentRound,
    setEnglish_RoundNumber_CurrentRound,
  ] = useState(null);
  const [english_RoundId_CurrentRound, setEnglish_RoundId_CurrentRound] =
    useState(null);
  const [
    englishCurrentRoundAndSubmission,
    setEnglishCurrentRoundAndSubmission,
  ] = useState(null);

  const [
    english_Predictions_CurrentRound,
    setEnglish_Predictions_CurrentRound,
  ] = useState(null);

  const [editModeSpanishLeague, setEditModeSpanishLeague] = useState(false);

  const [savedPredictions, setSavedPredictions] = useState(null);

  // to know if we saved the predictions in previous session
  const [isAfterLocalForage, setIsAfterLocalForage] = useState(false);

  const [spanish_Matches_NextRound, setSpanish_Matches_NextRound] = useState(
    []
  );
  const [spanish_League_NextRound, setSpanish_League_NextRound] =
    useState(null);
  const [spanish_RoundNumber_NextRound, setSpanish_Round_NextRound] =
    useState(null);
  const [spanish_RoundId_NextRound, setSpanish_RoundId_NextRound] =
    useState(null);

  const [spanish_BetAllowed_NextRound, setSpanish_BetAlloud_NextRound] =
    useState(null);

  const [spanish_Matches_CurrentRound, setSpanish_Matches_CurrentRound] =
    useState([]);
  const [spanish_League_CurrentRound, setSpanish_League_CurrentRound] =
    useState(null);
  const [
    spanish_RoundNumber_CurrentRound,
    setSpanish_RoundNumber_CurrentRound,
  ] = useState(null);
  const [spanish_RoundId_CurrentRound, setSpanish_RoundId_CurrentRound] =
    useState(null);

  const [
    spanishCurrentRoundAndSubmission,
    setSpanishCurrentRoundAndSubmission,
  ] = useState(null);

  const [
    spanish_Predictions_CurrentRound,
    setSpanish_Predictions_CurrentRound,
  ] = useState(null);

  const [spanish_Matches_LastRound, setSpanish_Matches_LastRound] = useState(
    []
  );
  const [spanish_League_LastRound, setSpanish_League_LastRound] =
    useState(null);
  const [spanish_RoundNumber_LastRound, setSpanish_RoundNumber_LastRound] =
    useState(null);
  const [spanish_RoundId_LastRound, setSpanish_RoundId_LastRound] =
    useState(null);

  const [spanishLastRoundAndSubmission, setSpanishLastRoundAndSubmission] =
    useState(null);

  const [spanish_Predictions_LastRound, setSpanish_Predictions_LastRound] =
    useState(null);

  // useEffect(() => {
  //   let jwt = localStorage.getItem("jwt-token")
  //   axios
  //     .get(`${BASE_URL}/api/get-predictions-banner`, {
  //       headers: {
  //         "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
  //         "jwt-token": jwt,
  //       },
  //     })
  //     .then((res) => {
  //       setBanners(res?.data?.banners)
  //     })
  // }, [])
  //check which clock is relevant (until or from)
  useEffect(() => {
    if (!spanishCurrentRoundAndSubmission) {
      if (spanishNextRound) {
        setCountDownData((prev) => {
          return {
            entriesUntilOrFrom: "until",
            roundName: spanishNextRound.name,
            roundTime: moment(spanishNextRound?.roundStart).subtract(
              1,
              "hours"
            ),
          };
        });
      } else {
        setCountDownData((prev) => {
          return {
            entriesUntilOrFrom: "from",
            roundName: spanishLastRoundAndSubmission?.round?.nextRoundName,
            roundTime: moment(
              spanishLastRoundAndSubmission?.round?.roundEnd
            ).add(5, "hours"),
          };
        });
      }
    } else {
      setCountDownData((prev) => {
        return {
          entriesUntilOrFrom: "from",
          roundName: spanishCurrentRoundAndSubmission?.round?.nextRoundName,
          roundTime: moment(
            spanishCurrentRoundAndSubmission?.round?.roundEnd
          ).add(5, "hours"),
        };
      });
    }
  }, [
    spanishCurrentRoundAndSubmission,
    spanishLastRoundAndSubmission?.round?.nextRoundName,
    spanishLastRoundAndSubmission?.round?.roundEnd,
    spanishNextRound,
  ]);
  useEffect(() => {
    let jwt = localStorage.getItem("jwt-token");
    const params = {
      campaignId: CURRENT_CAMPAIGN_ID,
      page: PagesEnum.predictions,
    };
    axios
      .post(`${BASE_URL}/api/get-banners`, params, {
        headers: {
          "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
          "jwt-token": jwt,
        },
      })
      .then((res) => {
        setBanners(res?.data?.banners);
      });
  }, []);

  const init = async () => {
    try {
      const username = sessionStorage.getItem("username");
      let jwt = localStorage.getItem("jwt-token");
      if (username && !jwt) return;
      if (user) {
        const res = await axios.get(
          `${BASE_URL}/api/get-rounds?campaignId=${CURRENT_CAMPAIGN_ID}`,
          {
            headers: {
              "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
              "jwt-token": jwt,
            },
          }
        );

        ////////////////////next round
        setSpanish_BetAlloud_NextRound(
          res?.data?.nextRoundWithPredictions?.betAllowed
        );

        setSpanish_Matches_NextRound(
          res?.data?.nextRoundWithPredictions?.round?.matches
        );

        setSpanish_Round_NextRound(
          res?.data?.nextRoundWithPredictions?.round?.name
        );

        setSpanish_RoundId_NextRound(
          res?.data?.nextRoundWithPredictions?.round?.id
        );

        setSpanish_League_NextRound(
          res?.data?.nextRoundWithPredictions?.league?.name
        );

        ///////////////////////current round

        setSpanish_Matches_CurrentRound(
          res?.data?.currentRoundWithPredictions?.round?.matches
        );

        setSpanish_RoundNumber_CurrentRound(
          res?.data?.currentRoundWithPredictions?.round?.name
        );

        setSpanish_RoundId_CurrentRound(
          res?.data?.currentRoundWithPredictions?.round?.id
        );

        setSpanish_League_CurrentRound(
          res?.data?.currentRoundWithPredictions?.league?.name
        );

        setSpanish_Predictions_CurrentRound(
          res?.data?.currentRoundWithPredictions?.submission?.matchPredictions
        );

        setSpanishCurrentRoundAndSubmission(
          res?.data?.currentRoundWithPredictions
        );

        setSpanish_Matches_LastRound(
          res?.data?.lastRoundWithPredictions?.round?.matches
        );

        setSpanish_RoundNumber_LastRound(
          res?.data?.lastRoundWithPredictions?.round?.name
        );

        setSpanish_RoundId_LastRound(
          res?.data?.lastRoundWithPredictions?.round?.id
        );

        setSpanish_League_LastRound(
          res?.data?.lastRoundWithPredictions?.league?.name
        );

        setSpanish_Predictions_LastRound(
          res?.data?.lastRoundWithPredictions?.submission?.matchPredictions
        );

        setSpanishLastRoundAndSubmission(res?.data?.lastRoundWithPredictions);
        res?.data?.nextRoundWithPredictions?.betAllowed?.from &&
          setSpanishEntriesFrom(
            new Date(res?.data?.nextRoundWithPredictions?.betAllowed?.from)
          );
        setSpanishEntriesUntil(
          new Date(res?.data?.nextRoundWithPredictions?.betAllowed?.until)
        );

        setSpanishPastRoundsPredictions(res?.data?.pastRoundsWithPredictions);
        setSpanishNextRound(res?.data?.nextRoundWithPredictions?.round);
        setSpanishPredictionsForNextRound(
          res?.data?.nextRoundWithPredictions?.submission?.matchPredictions
        );

        setnextSpanishRoundSubmission({
          submission: res?.data?.nextRoundWithPredictions?.submission,
        });
        if (!res?.data?.nextRoundWithPredictions?.submission) {
          const [nextRoundId, predictionArray] = await Promise.all([
            localforage.getItem("nextRoundId"),
            localforage.getItem("predictionArray"),
          ]);

          if (nextRoundId && predictionArray) {
            await axios.post(
              `${BASE_URL}/api/add-predictions`,
              {
                roundId: nextRoundId,
                predictions: predictionArray,
              },

              {
                headers: {
                  "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
                },
              }
            );

            if (sessionStorage.getItem("os") === "ios") {
              console.log("ios");
              ReactGA.event({
                category: "Submission Success - IOS",
                action: `${username} sent a new submission from IOS`,
              });

              window.webkit?.messageHandlers?.observer?.postMessage(
                JSON.stringify({ submit: "submission sent." })
              );
            } else if (sessionStorage.getItem("os") === "android") {
              console.log("android");
              ReactGA.event({
                category: "Submission Success - ANDROID",
                action: `${username} sent a new submission from ANDROID`,
              });
            } else {
              console.log("broser");
              ReactGA.event({
                category: "Submission Success - WEB",
                action: `${username} sent a new submission from WEB`,
              });
            }

            setIsAfterLocalForage(true);

            await localforage.removeItem("nextRoundId");
            await localforage.removeItem("predictionArray");
            init();
            return;
          }
        } else {
          await localforage.removeItem("nextRoundId");
          await localforage.removeItem("predictionArray");
        }

        setLoading(false);
      } else {
        axios
          .get(
            `${BASE_URL}/api/get-rounds-nonauth?campaignId=${CURRENT_CAMPAIGN_ID}`,
            {
              headers: {
                "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
                "jwt-token": jwt,
              },
            }
          )
          .then((res) => {
            setLoading(false);
            setSpanish_Matches_LastRound(
              res?.data?.lastRoundWithPredictions?.round?.matches
            );

            setSpanish_RoundNumber_LastRound(
              res?.data?.lastRoundWithPredictions?.round?.name
            );

            setSpanish_RoundId_LastRound(
              res?.data?.lastRoundWithPredictions?.round?.id
            );

            setSpanish_League_LastRound(
              res?.data?.lastRoundWithPredictions?.league?.name
            );

            setSpanish_Predictions_LastRound(
              res?.data?.lastRoundWithPredictions?.submission?.matchPredictions
            );

            setSpanishLastRoundAndSubmission(
              res?.data?.lastRoundWithPredictions
            );
            /////////////////////next round
            if (
              moment(
                res?.data?.currentRoundWithPredictions?.round?.roundStart
              ).isAfter(moment())
            ) {
              setSpanish_Matches_NextRound(
                res?.data?.currentRoundWithPredictions?.round?.matches
              );

              setSpanish_Round_NextRound(
                res?.data?.currentRoundWithPredictions?.round?.name
              );

              setSpanish_RoundId_NextRound(
                res?.data?.currentRoundWithPredictions?.round?.id
              );

              setSpanish_League_NextRound(
                res?.data?.currentRoundWithPredictions?.round?.name
              );
            } else {
              setSpanish_Matches_NextRound(
                res?.data?.nextRoundWithPredictions?.round?.matches
              );

              setSpanish_Round_NextRound(
                res?.data?.nextRoundWithPredictions?.round?.name
              );

              setSpanish_RoundId_NextRound(
                res?.data?.nextRoundWithPredictions?.round?.id
              );

              setSpanish_League_NextRound(
                res?.data?.nextRoundWithPredictions?.round?.name
              );

              setSpanishCurrentRoundAndSubmission(
                res?.data?.currentRoundWithPredictions
              );

              setSpanish_Matches_CurrentRound(
                res?.data?.currentRoundWithPredictions?.round?.matches
              );

              setSpanish_RoundNumber_CurrentRound(
                res?.data?.currentRoundWithPredictions?.round?.name
              );

              setSpanish_RoundId_CurrentRound(
                res?.data?.currentRoundWithPredictions?.round?.id
              );

              setSpanish_League_CurrentRound(
                res?.data?.currentRoundWithPredictions?.round?.name
              );
            }
            setSpanishCurrentRoundAndSubmission(
              res?.data?.currentRoundWithPredictions
            );

            ///////////////////////current round
            res?.data?.nextRoundWithPredictions?.betAllowed?.from &&
              setSpanishEntriesFrom(
                new Date(res?.data?.nextRoundWithPredictions?.betAllowed?.from)
              );
            setSpanishEntriesUntil(
              new Date(res?.data?.nextRoundWithPredictions?.betAllowed?.until)
            );
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isDataOld || editModeSpanishLeague) return;
    init();
  }, [user, isDataOld, editModeSpanishLeague]);
  // console.log(spanish_BetAllowed_NextRound)

  const [leaderboardLoading, setLeaderboardLoading] = useState(false);
  return (
    <PredictionsContext.Provider
      value={{
        english_Matches_NextRound,
        english_League_NextRound,
        english_RoundNumber_NextRound,
        english_RoundId_NextRound,
        english_Matches_CurrentRound,
        english_League_CurrentRound,
        english_RoundNumber_CurrentRound,
        english_RoundId_CurrentRound,
        englishCurrentRoundAndSubmission,
        english_Predictions_CurrentRound,
        spanish_Matches_NextRound,
        spanish_League_NextRound,
        spanish_RoundNumber_NextRound,
        spanish_RoundId_NextRound,
        spanish_BetAllowed_NextRound,
        spanish_Matches_CurrentRound,
        spanish_League_CurrentRound,
        spanish_RoundNumber_CurrentRound,
        spanish_RoundId_CurrentRound,
        spanish_Predictions_CurrentRound,
        spanishCurrentRoundAndSubmission,
        spanish_Matches_LastRound,
        spanish_League_LastRound,
        spanish_RoundNumber_LastRound,
        spanish_RoundId_LastRound,
        spanish_Predictions_LastRound,
        spanishLastRoundAndSubmission,
        loading,
        setLoading,
        isModify,
        setModify,
        currentLeague,
        setLeague,
        predictionArray,
        setPrediction,
        editModeSpanishLeague,
        setEditModeSpanishLeague,
        spanishEntriesFrom,
        spanishEntriesUntil,
        savedPredictions,
        setSavedPredictions,
        isAfterLocalForage,
        setIsAfterLocalForage,
        setLeaderboardLoading,
        leaderboardLoading,
        banners,
        countDownData,
        showCountDown,
        setShowCountDown,
      }}
    >
      {children}
    </PredictionsContext.Provider>
  );
};
