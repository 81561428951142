import axios from "axios";
import { createContext, useEffect, useRef, useState } from "react";
import { BASE_URL } from "../environment";
import isNullOrUndefined from "../helpers/is-null-or-undefined";
import { CURRENT_LEAGUE_ENDPOINT_1 } from "../leagues/leagues";
import { useHistory } from 'react-router';
import { Spinner } from 'react-bootstrap';
import localforage from "localforage";
import ReactGA from 'react-ga';
import { isAndroid, isBrowser, isIOS } from "react-device-detect"

export const UserContext = createContext(null);

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [hasNickname, setHasNickname] = useState(null);
  const [checkedNickname, setCheckedNickname] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isInApp, setIsInApp] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setIsInApp(Boolean(sessionStorage.getItem("isMobile")));
  }, []);

  const askUserForNicknameAttemptsLeft = useRef(1);

  useEffect(() => {
    ; (async () => {
      let jwt = localStorage.getItem("jwt-token")

      const username = sessionStorage.getItem("username")
      let isNewUser;

      if (username) {
        const {
          data: { jwtToken: fetchedServerToken },
        } = await axios.post(`${BASE_URL}/auth/server-login`, {
          serverKey: "Oi1KUI0hT2vOMdSluy8AF0ogbnN06XQD",
          name: "mxCodereClient",
        })
        const {
          data: { jwtToken: userJwtToken, userNotAlreadyExists },
        } = await axios.post(
          `${BASE_URL}/server-api/authenticate-user`,
          {
            UserId: username,
            originServer: "mxCodereClient",
          },
          {
            headers: {
              "jwt-token": fetchedServerToken,
            },
          }
        )
        if (userJwtToken) {
          localStorage.setItem("jwt-token", userJwtToken)
          jwt = userJwtToken;
          isNewUser = userNotAlreadyExists;
        }
      }

      if (jwt) {
        setUser(true)
        const signType = isNewUser ? "Registration" : "Login";
        const isTestEvent = process.env.NODE_ENV === "development";

        if (sessionStorage.getItem("os") === "ios") {
          console.log("ios")
          ReactGA.event({
            category: `${signType} Success - IOS${isTestEvent ? " test" : ""}`,
            action: `${username} sent a new registration from IOS`,
          })

          window.webkit?.messageHandlers?.observer?.postMessage(
            JSON.stringify({ submit: "registration sent." })
          )
        } else if (sessionStorage.getItem("os") === "android") {
          console.log("android")
          ReactGA.event({
            category: `${signType} Success - ANDROID${isTestEvent ? " test" : ""}`,
            action: `${username} sent a new registration from ANDROID`,
          })
        } else {
          console.log("broser")
          ReactGA.event({
            category: `${signType} Success - WEB${isTestEvent ? " test" : ""}`,
            action: `${username} sent a new registration from WEB`,
          })
        }
        jwt && (axios.defaults.headers.common["jwt-token"] = jwt)
      } else {
        setUser(false)
        axios.defaults.headers.common["jwt-token"] = ""
      }
      setLoading(false);

      //if predictions were saved in last session in local forage then we don't need welcom page
      // history.push(await localforage.getItem("predictionArray") && username ? `/${CURRENT_LEAGUE_ENDPOINT_1}` : `/welcome`)
    })()
  }, [CURRENT_LEAGUE_ENDPOINT_1, setUser])

  useEffect(() => {
    if (!user) return;
    axios.get(`${BASE_URL}/auth/profile`).then((res) => {
      const fetchedNickname = res.data?.user?.nickname;
      if (!isNullOrUndefined(fetchedNickname)) {
        setHasNickname(true);
      }
      setCheckedNickname(true);
    });
  }, [setHasNickname, user]);

  return loading ? (
    <div>
      <Spinner
        className="spinner"
        animation="border"
        style={{ color: "rgba(121, 192, 0, 1)" }}
      />
    </div>
  ) : (
    <UserContext.Provider
      value={{
        user,
        setUser,
        hasNickname,
        setHasNickname,
        checkedNickname,
        askUserForNicknameAttemptsLeft,
        isInApp,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
