import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./fonts/RobotoCondensed.ttf";
import "./fonts/RobotoCondensed-Light.ttf";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import ScrollToTop from "./GeneralComponents/ScrollToTop";
import ReactGA from "react-ga";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery";
import "popper.js/dist/popper";
import "bootstrap/dist/js/bootstrap";
import axios from "axios";
import { UserProvider } from "./contexts/UserContext";
import { ResultsScheduleProvider } from "./contexts/ResultsScheduleContext";
import { AccumulatorProvider } from "./contexts/AccumulatorContext";
import { PredictionsProvider } from "./contexts/PredictionsContext";
import { SideNavProvider } from "./contexts/SideNavContext";
import Maintenance from "./components/Maintenance";

ReactGA.initialize("UA-204804134-1", {
  useExistingGa: true,
  debug: false,
  // titleCase: false,
  // gaOptions: {
  //   userId: 123,
  // },
  // cookieFlags: "SameSite=None; Secure",
});
ReactGA.ga("create", "UA-204804134-1", "auto", {
  cookieFlags: "SameSite=None; Secure",
});

ReactGA.pageview(window.location.pathname + window.location.search);

const jwt = localStorage.getItem("jwt-token");
jwt && (axios.defaults.headers.common["jwt-token"] = jwt);

//mobile: username=(userName)&isMobile=1"
//web: username={userName}&temptoken={tempToken}&language={lang}
const urlParams = new URLSearchParams(window.location.search);

const isTrueParam = (param) => {
  return param && +param !== 0 && param !== "null" && param !== "undefined";
};

const userIdParam = urlParams.get("userId");
if (isTrueParam(userIdParam)) {
  sessionStorage.setItem("mobileToken", userIdParam);
} else {
  sessionStorage.removeItem("mobileToken");
}

const usernameParam = urlParams.get("username");
if (isTrueParam(usernameParam)) {
  sessionStorage.setItem("username", usernameParam);
} else {
  sessionStorage.removeItem("username");
}

const temptokenParam = urlParams.get("temptoken");
if (isTrueParam(temptokenParam)) {
  sessionStorage.setItem("webtoken", temptokenParam);
} else {
  sessionStorage.removeItem("webtoken");
}

if (
  !isTrueParam(userIdParam) &&
  !isTrueParam(usernameParam) &&
  !isTrueParam(temptokenParam)
) {
  localStorage.removeItem("jwt-token");
  axios.defaults.headers.common["jwt-token"] = null;
}

const languageParam = urlParams.get("language");
languageParam && sessionStorage.setItem("language", languageParam);

const isMobileParam = urlParams.get("isMobile");
isMobileParam && sessionStorage.setItem("isMobile", isMobileParam);

const osParam = urlParams.get("os");
isMobileParam && sessionStorage.setItem("os", osParam);

const isMaintenance = osParam === "android"; // Maintenance for android apps 19.5.2022

ReactDOM.render(
  <React.StrictMode>
    {isMaintenance ? (
      <Maintenance />
    ) : (
      <Router>
        <ScrollToTop>
          <UserProvider>
            <ResultsScheduleProvider>
              <PredictionsProvider>
                <AccumulatorProvider>
                  <SideNavProvider>
                    <App />
                  </SideNavProvider>
                </AccumulatorProvider>
              </PredictionsProvider>
            </ResultsScheduleProvider>
          </UserProvider>
        </ScrollToTop>
      </Router>
    )}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
