import React from "react"
import "./DesktopHeader.css"
import GreenHowToPlay from "../images/sideNavDesktop/GreenHowToPlay.png"
import BlackHowToPlay from "../images/sideNavDesktop/HowToPlay.png"
import { Link } from "react-router-dom"

const DesktopHeader = ({
  accumulatorHeader,
  leaderboard,
  predictionsHeader,
  resultsScheduleHeader,
  headerText,
  bgColor,
  textColor
}) => {
  if (predictionsHeader) return null;
  if (leaderboard) headerText = "CLASIFICACIÓN";
  if (accumulatorHeader) headerText = "PARLAYS";
  return (
    <React.Fragment>
      <div className={`text-center ${bgColor ? '' : 'bg-white'} desktop-header-container`} style={{backgroundColor: bgColor && bgColor}}>
        <div className="flex-1"></div>
        <div className="flex-1">
          <p className="desktop-header-text">{headerText}</p>
        </div>
        <div className="how-to-play-desktop flex-1">
          <Link
            to="/how-to-play"
            style={{ color: textColor ? textColor : "#353D49", textDecoration: "none" }}
          >
            <img src={leaderboard || accumulatorHeader || resultsScheduleHeader ? BlackHowToPlay : GreenHowToPlay} alt="question" />
            <br />
            <span>Cómo jugar</span>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DesktopHeader;
